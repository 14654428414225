@use "common" as *;
@use "_abstracts" as *;
@import '@angular/cdk/overlay-prebuilt.css';
// Some other files use `responsive-style` through `styles.scss`
// so this need to be @import
@import "responsive-style";

.em-l-container {
    margin-top: 17px;

    @include breakpoint(md) {
        margin-top: 0;
    }
}

.inner {
    position: absolute;
    top: 50% !important;
    text-align: center;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

h1 {
    font-size: 1.5rem;
    color: $font_color_darkblue;
    font-family: EMprintSemibold;
    margin: 0.5em 0;
}

.main-title {
    @include breakpoint(md) {
        padding-top: 1rem;
        padding-bottom: 3rem;
        font-size: xx-large;
    }
}

.em-c-solid-card__title {
    font-size: 1.1rem;
    margin-top: 1.2em;
}

.workorder-description {
    margin: 1.1em 0;
    display: block;
    font-size: 1.2em;
}

.mrma-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;

    .mrma__flex-wrapper {
        flex: 1;

        main {
            flex: 1;
            height: 100%;
        }
    }
}

.align-middle {
    vertical-align: middle;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -webkit-user-drag: none;
    /* Prevents dragging of images/divs etc */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

label {
    &.required-field:after {
        color: $red;
        float: right;
        content: "*Required";

        @include breakpoint-down(sm) {
            content: "";
        }
    }
}

.label {
    border-radius: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.55rem;
    padding: 0.25rem 0.625rem;
    display: inline-block;
    color: white;
}

.label-multi {
    margin-bottom: 5px;
    margin-right: 4px;
}

.label-inactive {
    background-color: $grey;
}

.label-default {
    color: $default-grey;
    background-color: $light-grey;
}

.label-error {
    background-color: $error-red;
}

.label-warning {
    background-color: $warning-orange;
}

.label-success {
    background-color: $success-green;
}

.label-info {
    background-color: $blue;
}

.label-grt {
    background-color: $light-blue;
}

.label-remark {
    color: $font_color_orange;
    background-color: $light-green;
}

.status-text-default,
.status-text-error,
.status-text-warning,
.status-text-success {
    color: $light-grey;
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 0;
}

.status-text-error {
    color: $error-red;
}

.status-text-warning {
    color: $warning-orange;
}

.status-text-success {
    color: $success-green;
}

.result-status-text-error {
    color: $error-red;
}

.label-circle {
    border-radius: 50%;
    background: $blue;
    width: 17px;
    color: white;
    font-size: 10px;
    height: 17px;
    padding: 2px;
    display: inline-block;
}

.em-c-icon--blue {
    color: $icon_color_blue;
    fill: $icon_color_blue;
}

.em-c-icon--gray {
    color: $icon_color_gray;
    fill: $icon_color_gray;
}

.footer {
    background: $lighter-grey;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    float: right;
    width: 100%;
    color: $grey;
    padding-left: 1rem;
}

.footer-element {
    height: 2.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.details-line {
    margin-top: 7px;
    margin-bottom: 1rem;
}

.section-title {
    font-size: large;
}

.footer-right-button {
    @include breakpoint(md) {
        margin-right: 20px;
        width: 150px;
    }
}

.back-button {
    font-size: 1em;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
}

.blue-button {
    color: $font_color_blue;

    &:hover {
        cursor: pointer;
    }
}

.gray-out-text {
    color: $icon_color_darkgray;
}

.grey-button-disabled {
    filter: grayscale(100%);
    color: #a5a5a5;

    &:hover {
        cursor: not-allowed !important;
    }
}

.inline {
    display: inline-block;
}

.display-none {
    display: none;
}

.float-right {
    float: right;
}

.em-c-icon--green {
    color: $icon_color_green;
    fill: $icon_color_green;
}

.em-c-icon--red {
    color: $icon_color_red;
    fill: $icon_color_red;
}

.em-c-icon--yellow {
    color: $yellow;
    fill: $yellow;
}

.em-c-tabs__list {
    background-attachment: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

.green-box,
.green-box input {
    border-width: 2px;
    border-color: $green;
    margin: 0px;
}

.red-box,
.red-box input {
    border-width: 2px;
    border-color: $red;
    margin: 0px;
}

.hcp-calibration-icon {
    top: 15px;
    left: 14px;
    position: absolute;
}

.text-error {
    color: $red;
    padding-right: 0;
    font-size: 0.88rem;
    padding-top: 1px;
    left: 5px;
    position: relative;
}

.results-table {
    width: 75%;
}

.em-is-disabled {
    .date-picker {
        color: rgb(84, 84, 84) !important;
    }
}

input {
    font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-style: normal;
}

.em-u-font-style-underline {
    text-decoration: underline;
}

.a-icon,
.a-text {
    vertical-align: middle;
    display: inline-block;
    font-family: EMprintSemibold, HelveticaNeue, Helvetica, Arial, sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;

    :hover {
        color: $hover-over;
    }
}

.wo-star-booked-mark {
    @include breakpoint-down(sm) {
        position: relative;
        left: 5px;
        top: -3px;
    }
}

.em-c-text-link {
    text-decoration: none;
    cursor: pointer;
    color: #5d5d5d;
    width: fit-content;
}

.custom-right-item {
    margin-left: auto;
}

.custom-left-item {
    margin-right: auto;
}

:focus {
    outline: none !important;
}

.no-border {
    border: none;
}

.no-background-color {
    background-color: unset !important;
}

.toast-container {
    z-index: 1002 !important;
}

.toast-info,
.toast-error,
.toast-warning,
.toast-success {
    background-image: none !important;
}

.toast-title {
    font-weight: normal !important;
}

.toast-top-right {
    top: 3rem !important;

    @include breakpoint-down(xs) {
        top: 0 !important;
        right: 0 !important;
        width: 100% !important;
        padding: 12px !important;
    }
}

.toast-container .ngx-toastr {
    @include breakpoint(sm) {
        width: 500px !important;
    }

    @include breakpoint-down(xs) {
        margin: 0 0 10px !important;
        width: 100% !important;
    }

    padding: 15px !important;
    background-position: 15px 15px !important;
    box-shadow: none !important;

    &:hover {
        box-shadow: 0px 0px 6px black !important;
    }
}

.global-error-handler {
    border-top: 1px solid;
    margin-top: 1em;
    padding-top: 1em;

    p {
        margin-bottom: 0;
        line-height: inherit;
    }
}

.average-completed.average-value--failed {
    color: #ff453a;
}

.average-completed.average-value--passed {
    color: #30d158;
}

.width75 {
    width: 75% !important;
}

.text-center {
    text-align: center;
    justify-content: center;
}

.hover-dropdown-master {
    position: relative;

    &:hover {
        .hover-dropdown {
            display: block;
        }
    }
}

.toaster-error-meta {
    font-size: 0.6em;
    margin-top: 1em;
    display: block;
}

.loading-bar {
    height: 6px;
    width: 100%;
    display: flex;
    background: $lighter-grey;
    margin-top: -1px;

    &.loading {
        background: $progress-bar-background;

        &::before {
            background-color: $progress-bar-load;
            height: 6px;
            width: 100%;
            content: "";
            animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
    }

    @keyframes running-progress {
        0% {
            margin-left: 0;
            margin-right: 100%;
        }

        50% {
            margin-left: 25%;
            margin-right: 0%;
        }

        100% {
            margin-left: 100%;
            margin-right: 0;
        }
    }
}

// Easter Eggs 🥚
.glorious-rainbow {
    background-image: linear-gradient(
        90deg,
        red 0%,
        #ff9a00 10%,
        #d0de21 20%,
        #4fdc4a 30%,
        #3fdad8 40%,
        #2fc9e2 50%,
        #1c7fee 60%,
        #5f15f2 70%,
        #ba0cf8 80%,
        #fb07d9 90%,
        red 100%
    ) !important;
    background-position: 0 0;
    background-size: 200% 100% !important;
    animation: glorious-animation 4s linear infinite;
}

@keyframes glorious-animation {
    to {
        background-position: -200% 0;
    }
}

.toast-expand-section-error {
    margin-bottom: 0;
    margin-top: 1rem;
    border-top: 1px solid #e5e5e5;

    .sap-error-message {
        margin: 1rem 0 0;
    }
}
.toast-expand-header-error {
    margin-bottom: 0;
    border: none;
}

.global-bold-text {
    font-weight: bold;
    font-family: 'EMprintSemibold';
}

.relative-container {
    position: relative;
}

.em-u-width-15 {
    width: 15% !important;
}
