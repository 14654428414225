$icon_color_yellow: #ffd700;
$icon_color_blue: #235695;
$icon_color_light_blue: #2c97ff;
$icon_color_gray: #cccccd;
$icon_color_darkgray: #757575;
$icon_color_green: #00af53;
$icon_color_red: #d93900;
$icon_color_white: #ffffff;

$font_color_red: #d93900;
$font_color_darkblue: #235695;
$font_color_blue: #0c69b0;
$font_color_darkgrey: #5d5d5d;
$font_color_lightgrey: #8e8e97;
$font_color_black: #112;
$font_color_placeholder: #d4d4d4;
$font_color_orange: #ffc874;

$yellow: #ffd700;
$light-yellow: #fffde4;
$blue: #3190d9;
$dark-blue: #235695;
$gray: #cccccd;
$green: #00af53;
$red: #d93900;
$light-blue: #09bcff;
$lighter-blue: #e0eeff;
$light-green: #fff2de;

// From app-colors.scss
$lighter-grey: #f5f5f5;
$athens-grey: #f6f6f8;
$grey: #8e8e93;
$default-grey: #606060;
$disable-grey: #95989a;
$light-grey: #cccccd;
$warning-yellow: #f4b817;
$warning-orange: #ff9d0a;
$success-green: #399043;
$error-red: #ce3838;
$hover-over: #112;

// $blue: #0c69b0; conflicting with existing one, keep the current one

$layout-section-header-blue: #235695;
$layout-section-title-backgroud: #f5f5f5;
$layout-section-border: #cccccd40;
$layout-hr: #e5e5e5;
$layout-bottom-border: #eee7e7;
$layout-light-border: #edeeee;
$layout-section-header-background__grey: #efeff4;
$layout-section-background__light-grey: #fafafa;
$mobile-footer__light-grey: #f1efef;
$tag-border__light-grey: #dfdbdb;
$layout-section-background__dark-grey: #90909029;
$layout-offline-header-bottom-border-grey: #dadada;
$layout-offline-content-bottom-border-grey: #f1f1f1;
$layout-header-menu-blue: #002f6c;
$layout-header-menu-bright-blue: #084088;
$card-selection-dark-blue: #d8e0e9;

$progress-bar-load: #2ac4ff;
$progress-bar-background: #cdf1ff;

$badge-red: #a93535;
$button-background-grey: #fbfbfa;

$information-background-lightblue: #E9FBFF
