@use "common" as *;

@include breakpoint(md) {
    .desktop-margin-bottom {
        &__sm {
            margin-bottom: 1rem;
        }
        &__md {
            margin-bottom: 2rem;
        }
        &__lg {
            margin-bottom: 4rem;
        }
    }
}
