/* Responsive Breakpoint Values
  Example usage: @include breakpoint(md) {}
*/
$xs: 480;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1440px;

/* Responsive Breakpoint Mixin with mobile first approach. */
@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: 480px) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: 1440px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, and xl";
  }
}

// Responsive Breakpoint Mixin to focus on selected size and below.
@mixin breakpoint-down($class) {
  @if $class == xs {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: 991.98px) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: 1199.98px) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: 1439.98px) {
      @content;
    }
  } @else {
    @warn "Breakpoint-down mixin supports: xs, sm, md, lg, and xl";
  }
}

@mixin wordwrap() {
    word-break: break-word;
    hyphens: auto;
  }
